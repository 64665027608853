<template>
  <div>
    <v-alert
        dense
        text
        type="success"
        v-if="MostrarSaveOk === 1"
      >
        Registro almacenado correctamente
      </v-alert>
  
            <v-data-table
              :headers="headers"
              :items="desserts"
              :search="search"
              sort-by="calories"
              class="flex-1-1-100 ma-2 pa-2 elevation-1"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <!-- <v-toolbar-title>Consulta de profesionales - Rethus</v-toolbar-title> -->
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                  <v-dialog
                    v-model="dialog"
                    max-width="500px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Nuevo Profesional
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                      </v-card-title>
          
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="6"
                              md="4"
                            >

                                <v-select
                                v-model="editedItem.TipoId"
                                :items="TiposIdentificacion"
                                label="Tipo Id"
                                solo
                                width="500"
                              ></v-select>

                            <!--   <v-text-field
                                v-model="editedItem.TipoId"
                                label="Tipo Id"
                              ></v-text-field> -->
                            </v-col>
                            <v-col
                              cols="12"
                              sm="6"
                              md="4"
                            >
                              <v-text-field
                                v-model="editedItem.NroId"
                                label="Nro Id"
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="6"
                              md="4"
                            >
                              <v-text-field
                                v-model="editedItem.PrimerApellido"
                                label="Primer Apellido"
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="6"
                              md="4"
                            >
                              <v-text-field
                                v-model="editedItem.SegundoApellido"
                                label="Segundo Apellido"
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="6"
                              md="4"
                            >
                              <v-text-field
                                v-model="editedItem.PrimerNombre"
                                label="Primer Nombre"
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="6"
                              md="4"
                            >
                              <v-text-field
                                v-model="editedItem.SegundoNombre"
                                label="Segundo Nombre"
                              ></v-text-field>
                            </v-col>                          
                          </v-row>
                        </v-container>
                      </v-card-text>
          
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="close"
                        >
                          Cancelar
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="save"
                        >
                          Guardar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5">Esta seguro de eliminar el profesional?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  small
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initialize"
                >
                  Reset
                </v-btn>
              </template>
            </v-data-table>
   </div>         
  
    </template>
  
  
  <script>
  
  import auth from "@/auth";
  import axios from 'axios'
  
  
    export default {
      data: () => ({
        search: '',
        dialog: false,
        dialogDelete: false,
        MostrarSaveOk:0,
        headers: [
  /*         {
            text: 'TipoId',
            align: 'start',
            sortable: false,
            value: 'name',
          }, */
          { text: 'TipoId', value: 'TipoId' },
          { text: 'NroId', value: 'NroId' },
          { text: 'PrimerApellido', value: 'PrimerApellido' },
          { text: 'SegundoApellido', value: 'SegundoApellido' },
          { text: 'PrimerNombre', value: 'PrimerNombre' },
          { text: 'SegundoNombre', value: 'SegundoNombre' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        desserts: [],
        ProfesionalRethus:[],
        editedIndex: -1,
        editedItem: {
          TipoId: '',
          NroId: '',
          PrimerApellido: '',
          SegundoApellido: '',
          PrimerNombre: '',
          SegundoNombre: '',
  
        },
        defaultItem: {
          TipoId: '',
          NroId: '',
          PrimerApellido: '',
          SegundoApellido: '',
          PrimerNombre: '',
          SegundoNombre: '',
        },
        TiposIdentificacion:[
          {text: 'CC', value: 'CC' },
          {text: 'CE', value: 'CE' },
          {text: 'PT', value: 'PT' }

        ]
      }),
  
  
        beforeMount() { 
  
          this.MostrarSaveOk = 0;
            //TODO: consultar si la persona existe
            try 
            {
                  const Key = 'Bearer ' + auth.getUserLogged().token;
                  axios.get(process.env.VUE_APP_RUTA_API +'/rethuses?_limit=20000' ,{
                  headers: {
                    Authorization: Key
                  }
                  }).then (response =>{
                    
                    console.log("response.data:",response.data)
  
                  //Si la empresa existe en la base de datos, la edita con los nuevos valores
                  if(response.data)
                  {
                    this.desserts = response.data
                    //console.log("response.data:",this.desserts)
                  }
                  else{
                    alert("Error al tratar de editar la empresa, commit");
                  } 
            })           
            } 
            catch (error)           
            {
                console.log("El usuario no existe",error)
              
            }//Fin catch
          
      
        },
  
      computed: {
        formTitle () {
          return this.editedIndex === -1 ? 'Nuevo Profesional' : 'Editar Profesional'
        },
      },
  
      watch: {
        dialog (val) {
          val || this.close()
        },
        dialogDelete (val) {
          val || this.closeDelete()
        },
      },
  
      created () {
        this.initialize()
      },
  
      methods: {
        initialize () {
  
        },
  
  
        async ActualizarRegistro(Profesional)
          {    
            const Key = 'Bearer ' + auth.getUserLogged().token;         
                var id = Profesional.id;
                try {
                  const response = await axios.put(process.env.VUE_APP_RUTA_API + '/rethuses/'+id,Profesional,
                  {
                      headers:{Authorization: Key},
                  });
                      //console.log("ok -> " + response.data);
                      return response.data;
                  } 
                  catch (err) 
                  {
                      console.log(err)
                  } 
   
          },
  
        EliminarRegistro(item){
        
           const Key = 'Bearer ' + auth.getUserLogged().token;
                try {
                  axios.delete(process.env.VUE_APP_RUTA_API +'/rethuses/' +item.id, {
                    headers: {
                      Authorization: Key
                    },
                  })              
                } catch (error) {
                  console.log("Error al tratar de eliminar el registro")                
                } 
  
        },
  
  
        editItem (item) {
          this.editedIndex = this.desserts.indexOf(item)
          this.editedItem = Object.assign({}, item)
          this.dialog = true
        },
  
        deleteItem (item) {
          this.editedIndex = this.desserts.indexOf(item)
          this.editedItem = Object.assign({}, item)
          this.dialogDelete = true        
        },
  
        deleteItemConfirm () {        
          this.EliminarRegistro(this.desserts[this.editedIndex])
          this.desserts.splice(this.editedIndex, 1)
          this.closeDelete()
        },
  
        close () {
          this.dialog = false
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
        },
  
        closeDelete () {
          this.dialogDelete = false
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
        },
  
        save () {
            if (this.editedIndex > -1) {
              //console.log("DITANDO....")
            Object.assign(this.desserts[this.editedIndex], this.editedItem)
            this.ActualizarRegistro(this.desserts[this.editedIndex], this.editedItems);
            //console.log("this.desserts:",this.desserts[this.editedIndex], this.editedItem)
          } else {

            //cODIGO UTILIZADO PARA GUARDAR EL REGISTRO NUEVO
            
            const Key = 'Bearer ' + auth.getUserLogged().token;
            console.log("va a guardar:",this.editedItem,"ruta:",process.env.VUE_APP_RUTA_API,"key:",Key)
                try {
                  axios.post(process.env.VUE_APP_RUTA_API +'/rethuses/', this.editedItem, {
                    headers: {
                      Authorization: Key
                    },
                  })   
                  this.MostrarSaveOk=1           
                } catch (error) {
                  console.log("Error al tratar de insertar el registro")                
                }           
  
            this.desserts.push(this.editedItem)          
          }
          this.close()
        },
      },
    }
  </script>